import React, { FC } from "react";

import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";

import cn from "classnames";

import { useWordPressMediaQuery } from "@/entities/wordpress/api/media";
import { usePostLinkParams } from "@/features/public/blog/hooks";
import ArrowIcon from "@/features/public/home/assets/ArrowIcon";
import LockHoverIcon from "@/features/public/home/assets/LockHoverIcon";
import LockIcon from "@/features/public/home/assets/LockIcon";
import { Account_Api_EntryPointEnum } from "@/graphql-schema-types.generated";
import { useSetEntryPoint } from "@/lib/saveEntryPoint";

import PostCategories from "../PostCategories";
import { IMAGE_HEIGHT, IMAGE_WIDTH } from "./constants";
import { PostCardProps } from "./types";

import styles from "./styles.module.scss";

const PostComments = dynamic(() => import("../PostComments"));

const PostCard: FC<PostCardProps> = ({
  post,
  priority,
  className,
  showComments,
  viewType,
  media,
}) => {
  const { status, data } = useWordPressMediaQuery(post.featured_media, media);

  const { setEntryPoint } = useSetEntryPoint();
  const productUrl = post.acf.product_url;
  const linkParams = usePostLinkParams(post, productUrl);
  const mediaSizes = data?.media_details?.sizes;
  const pictureSrc =
    mediaSizes?.medium?.source_url ??
    mediaSizes?.medium_large?.source_url ??
    mediaSizes?.large?.source_url;
  const isLocked = post.acf.payment_post;

  const handleClick = () => {
    if (isLocked) {
      setEntryPoint(Account_Api_EntryPointEnum.EntryPointPaymentPost);
    }
  };

  return (
    <article
      className={cn(styles.card, className, {
        [styles.horizontalPrimary]: viewType === "horizontalPrimary",
        [styles.horizontalSmall]: viewType === "horizontalSmall",
        [styles.verticalBig]: viewType === "verticalBig",
        [styles.verticalMedium]: viewType === "verticalMedium",
        [styles.noComments]: !showComments,
      })}
    >
      <figure
        className={cn(styles.picture, {
          [styles.horizontalPrimary]: viewType === "horizontalPrimary",
          [styles.horizontalSmall]: viewType === "horizontalSmall",
          [styles.verticalBig]: viewType === "verticalBig",
          [styles.verticalMedium]: viewType === "verticalMedium",
        })}
      >
        <Link
          as={linkParams.as}
          href={
            productUrl
              ? linkParams.href
              : {
                  pathname: linkParams.pathname,
                  query: linkParams.query,
                }
          }
          prefetch={false}
        >
          <a onClick={handleClick}>
            {status === "loading" ? (
              <span className={styles.imagePlaceholder} />
            ) : pictureSrc ? (
              <>
                <Image
                  alt={post.title.rendered}
                  height={IMAGE_HEIGHT}
                  priority={priority}
                  src={pictureSrc}
                  width={IMAGE_WIDTH}
                />
                {isLocked && (
                  <>
                    <div className={styles.lockIcon}>
                      <LockIcon />
                    </div>
                    <div className={cn(styles.lockIcon, styles.lockIconHover)}>
                      <LockHoverIcon />
                    </div>
                  </>
                )}
              </>
            ) : null}
          </a>
        </Link>
      </figure>
      <div
        className={cn(styles.content, {
          [styles.horizontalPrimary]: viewType === "horizontalPrimary",
          [styles.horizontalSmall]: viewType === "horizontalSmall",
          [styles.verticalBig]: viewType === "verticalBig",
          [styles.verticalMedium]: viewType === "verticalMedium",
        })}
      >
        <Link
          as={linkParams.as}
          href={
            productUrl
              ? linkParams.href
              : {
                  pathname: linkParams.pathname,
                  query: linkParams.query,
                }
          }
          prefetch={false}
        >
          <a onClick={handleClick}>
            {isLocked ? (
              <div className={styles.lockedText}>
                <span>Free sign up to read</span>
                <ArrowIcon />
              </div>
            ) : (
              <PostCategories postId={post.id} />
            )}
            <div
              className={cn(styles.title, {
                [styles.locked]: isLocked,
              })}
              dangerouslySetInnerHTML={{
                __html: post.title.rendered,
              }}
            />
            {showComments && <PostComments postId={post.id} />}
          </a>
        </Link>
      </div>
    </article>
  );
};

export default PostCard;
