import React, { ComponentProps, FC } from "react";

import cn from "classnames";

import TriangleRightIcon from "@/features/public/home/assets/TriangleRightIcon";

import css from "./index.module.css";

export const PlayButton: FC<ComponentProps<"button">> = ({
  className,
  ...rest
}) => (
  <button
    aria-label="Play"
    className={cn(css.playButton, className)}
    type="submit"
    {...rest}
  >
    <TriangleRightIcon />
  </button>
);
