import React, { FC, useCallback, useMemo, useState } from "react";

import dynamic from "next/dynamic";

import { useInView } from "react-cool-inview";

import { useWordPressPostsQuery } from "@/entities/wordpress/api/posts";

import { SectionTitleType } from "../../types";
import PostCard from "../PostCard";
import { TitleLink } from "../TitleLink";

import styles from "./styles.module.scss";

const Slider = dynamic(() => import("./Slider"), {
  ssr: false,
});

type Props = {
  title: SectionTitleType;
  initialData462: any;
};

const QuestionsAndAnsweres: FC<Props> = ({ title, initialData462 }) => {
  const [showMore, setShowMore] = useState(false);

  const { data, refetch } = useWordPressPostsQuery(
    {
      page: 1,
      per_page: 8,
      tags: [462],
    },
    { initialData: initialData462 }
  );

  const { observe } = useInView({
    onEnter: () => refetch(),
    unobserveOnEnter: true,
  });

  const sortedPosts = useMemo(
    () =>
      (data?.data ?? [])
        .sort((a, b) => {
          const orderA =
            a.acf.post_order_number === ""
              ? 0
              : Number(a.acf.post_order_number);
          const orderB =
            b.acf.post_order_number === ""
              ? 0
              : Number(b.acf.post_order_number);
          return orderB - orderA;
        })
        .slice(0, 8),
    [data]
  );

  const mobilePosts = useMemo(
    () => sortedPosts.slice(0, showMore ? 8 : 4),
    [sortedPosts, showMore]
  );

  const handleShowMore = useCallback(() => setShowMore(true), []);

  return (
    <section ref={observe}>
      <div className={styles.header}>
        <TitleLink href={title.button_url}>{title.button_text}</TitleLink>
      </div>
      <Slider sortedPosts={sortedPosts} />
      <div className={styles.containerMobile}>
        {mobilePosts.map((p) => (
          <div key={p.id} className={styles.post}>
            <PostCard post={p} viewType="horizontalSmall" />
          </div>
        ))}
        {!showMore && (
          <button
            className={styles.showMore}
            onClick={handleShowMore}
            type="button"
          >
            Show more
          </button>
        )}
      </div>
    </section>
  );
};

export default QuestionsAndAnsweres;
