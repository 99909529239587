import React from "react";

const LockHoverIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.82227 3.89062H12.9513V5.12671H15.545V3.89062C15.545 2.45814 14.3837 1.29688 12.9513 1.29688H7.82227C6.38978 1.29688 5.22852 2.45814 5.22852 3.89062V9.01733H7.82227V3.89062Z"
      fill="currentColor"
    />
    <rect
      fill="currentColor"
      height="11.5908"
      rx="2.59375"
      width="15.603"
      x="2.59375"
      y="7.82178"
    />
  </svg>
);

export default LockHoverIcon;
