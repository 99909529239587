import React, { useMemo } from "react";

import Image from "next/image";
import Link from "next/link";

import { PlayButton } from "@/components/PlayButton";
import { useWordPressAuthorQuery } from "@/entities/wordpress/api/authors";
import { useWordPressMediaQuery } from "@/entities/wordpress/api/media";
import { usePostLinkParams } from "@/features/public/blog/hooks";

import PostCategories from "../../PostCategories";
import { WEBINAR_CATEGORY_ID } from "./constants";
import { WebinarProps } from "./types";

import styles from "./styles.module.scss";

const Webinar = ({ post, media }: WebinarProps) => {
  const { data: mediaData } = useWordPressMediaQuery(
    post.featured_media,
    media[0]
  );

  const sizes = mediaData?.media_details?.sizes;

  const { data: authorData } = useWordPressAuthorQuery(post.author);

  const avatar = useMemo(
    () => authorData?.avatar_urls[96] ?? authorData?.avatar_urls[48],
    [authorData]
  );

  const linkParams = usePostLinkParams(post);

  const isWebinar = useMemo(
    () => post.categories.includes(WEBINAR_CATEGORY_ID),
    [post.categories]
  );

  return (
    <article className={styles.webinarCard}>
      <figure className={styles.picture}>
        <Link
          as={linkParams.as}
          href={{
            pathname: linkParams.pathname,
            query: linkParams.query,
          }}
          passHref
          prefetch={false}
        >
          <a>
            {sizes?.medium?.source_url && (
              <Image
                alt={post.title.rendered}
                height={533}
                layout="intrinsic"
                priority
                src={sizes.medium.source_url}
                width={800}
              />
            )}
            {isWebinar && <PlayButton className={styles.playButton} />}
          </a>
        </Link>
      </figure>
      <div className={styles.content}>
        <Link
          as={linkParams.as}
          href={{
            pathname: linkParams.pathname,
            query: linkParams.query,
          }}
          passHref
          prefetch={false}
        >
          <a>
            {isWebinar ? (
              <div className={styles.category}>Watch webinar</div>
            ) : (
              <PostCategories className={styles.category} postId={post.id} />
            )}
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: post.title.rendered }}
            />
          </a>
        </Link>
      </div>
      <div className={styles.author}>
        <div className={styles.authorAvatar}>
          {avatar != null && (
            <Image
              alt={authorData?.name ?? ""}
              height={96}
              src={avatar}
              width={96}
            />
          )}
        </div>
        <div>
          <div className={styles.authorName}>
            {authorData?.name ? `by ${authorData.name}` : null}
          </div>
        </div>
      </div>
    </article>
  );
};

export default Webinar;
