import React from "react";

const LockIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12.9513 3.89062H7.82227V11.5503H12.9513V3.89062ZM7.82227 1.29688C6.38978 1.29688 5.22852 2.45814 5.22852 3.89062V11.5503C5.22852 12.9828 6.38978 14.144 7.82227 14.144H12.9513C14.3837 14.144 15.545 12.9828 15.545 11.5503V3.89062C15.545 2.45814 14.3837 1.29688 12.9513 1.29688H7.82227Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <rect
      fill="currentColor"
      height="11.5908"
      rx="2.59375"
      width="15.603"
      x="2.59375"
      y="7.82178"
    />
  </svg>
);

export default LockIcon;
