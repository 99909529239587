import React from "react";

import Image from "next/image";

import { PARTNERS } from "./constants";

import styles from "./styles.module.scss";

const MediaPartners = () => (
  <section className={styles.root}>
    <div className={styles.inner}>
      <div className={styles.title}>As seen in</div>
      {PARTNERS.map((partner, key) => (
        <div key={key} className={styles.image}>
          <Image alt={partner.alt} src={partner.src} />
        </div>
      ))}
    </div>
  </section>
);
export default MediaPartners;
