import React from "react";

import cn from "classnames";

import { usePostCategories } from "@/features/public/blog/hooks";

import { PostCategoriesProps } from "./types";

import styles from "./styles.module.scss";

const PostCategories = ({ postId, className }: PostCategoriesProps) => {
  const categories = usePostCategories({ postId, withRootItem: false });

  return (
    <div
      className={cn(styles.category, className)}
      dangerouslySetInnerHTML={{
        __html:
          categories.length > 0
            ? categories.map((category) => category.title).join(", ")
            : "&nbsp;",
      }}
    />
  );
};

export default PostCategories;
