import React, { useEffect, useMemo } from "react";

import { useInView } from "react-cool-inview";

import { useWordPressPostsQuery } from "@/entities/wordpress/api/posts";
import { useOpenPopupCalendly } from "@/hooks";

import PostCard from "../PostCard";
import { TitleButton, TitleLink } from "../TitleLink";
import { WhatYouNeedToKnowProps } from "./types";
import Webinar from "./Webinar";

import styles from "./styles.module.scss";

const WhatYouNeedToKnow = ({
  trendingNowTitle,
  whatYouNeedToKnowContent,
  initialData460,
  initialData461,
  media,
}: WhatYouNeedToKnowProps) => {
  const { observe } = useInView({
    onEnter: () => refetchTrendingNowData(),
    unobserveOnEnter: true,
  });
  const openPopupCalendly = useOpenPopupCalendly(
    whatYouNeedToKnowContent.mobile_button_url
  );
  const { data: mainPost, refetch: refetchMainPost } = useWordPressPostsQuery(
    {
      page: 1,
      per_page: 1,
      tags: [460],
    },
    { initialData: initialData460 }
  );

  const { data: trendingNowData, refetch: refetchTrendingNowData } =
    useWordPressPostsQuery(
      {
        page: 1,
        per_page: 4,
        tags: [461],
      },
      { initialData: initialData461 }
    );

  useEffect(() => {
    void refetchMainPost();
  }, [refetchMainPost]);

  const trendingNowPosts = useMemo(
    () =>
      (trendingNowData?.data ?? [])
        // eslint-disable-next-line sonarjs/no-identical-functions
        .sort((a, b) => {
          const orderA =
            a.acf.post_order_number === ""
              ? 0
              : Number(a.acf.post_order_number);
          const orderB =
            b.acf.post_order_number === ""
              ? 0
              : Number(b.acf.post_order_number);
          return orderB - orderA;
        })
        .slice(0, 4),
    [trendingNowData]
  );

  return (
    <section className={styles.postsContainer}>
      <div className={styles.mainPost}>
        <TitleButton className={styles.titleTablet} onClick={openPopupCalendly}>
          {whatYouNeedToKnowContent.mobile_button_text}
        </TitleButton>
        <TitleLink
          className={styles.titleDesktop}
          href={whatYouNeedToKnowContent.button_url}
        >
          {whatYouNeedToKnowContent.button_text}
        </TitleLink>
        {mainPost && <Webinar media={media} post={mainPost.data[0]} />}
      </div>
      <div ref={observe} className={styles.postColumn}>
        <TitleLink href={trendingNowTitle.button_url}>
          {trendingNowTitle.button_text}
        </TitleLink>
        {trendingNowPosts.map((p, index) => (
          <div key={index} className={styles.post}>
            <PostCard
              showComments
              media={media[index + 1]}
              post={p}
              viewType="horizontalSmall"
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhatYouNeedToKnow;
