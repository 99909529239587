class ServerRedirectServiceBase {
  public to(url: string) {
    return {
      redirect: {
        destination: url,
        permanent: false,
      },
    };
  }

  public toNotFound(): { notFound: true } {
    return {
      notFound: true,
    };
  }
}

export const ServerRedirectService = new ServerRedirectServiceBase();
