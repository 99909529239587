import BbcLogo from "./assets/bbc.svg";
import IndependentLogo from "./assets/independent.svg";
import MirrorLogo from "./assets/mirror.svg";
import MsnLogo from "./assets/msn.svg";
import TechCrunchLogo from "./assets/tc.svg";
import YahooLogo from "./assets/yahoo.svg";

export const PARTNERS = [
  { src: TechCrunchLogo, alt: "TechCrunch" },
  { src: BbcLogo, alt: "BBC" },
  { src: IndependentLogo, alt: "Independent" },
  { src: YahooLogo, alt: "Yahoo" },
  { src: MsnLogo, alt: "msn" },
  { src: MirrorLogo, alt: "Mirror" },
];
