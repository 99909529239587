import React, { ComponentProps, FC } from "react";

import Link from "next/link";

import cn from "classnames";

import { TitleLinkProps } from "./types";

import styles from "./styles.module.scss";

export const TitleLink: FC<TitleLinkProps> = ({
  children,
  className,
  ...rest
}) => (
  <Link prefetch={false} {...rest}>
    <a className={cn(styles.titleLink, className)}>{children}</a>
  </Link>
);

export const TitleButton: FC<ComponentProps<"span">> = ({
  children,
  className,
  ...rest
}) => (
  <span className={cn(styles.titleLink, className)} role="button" {...rest}>
    {children}
  </span>
);
