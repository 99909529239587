import { useCallback, useEffect } from "react";

import { useUserInfo } from "@/core/Auth/hooks";
import { Account_Api_EntryPointEnum } from "@/graphql-schema-types.generated";

import { ENTRYPOINT_STORAGE_KEY } from "./constants";
import { isDOM } from "./isDom";

const entryPointForRoute = (route: string | undefined): string | undefined => {
  switch (route) {
    case "advanced-rent":
      return Account_Api_EntryPointEnum.EntryPointManagementAdvanceRent;
    case "buy-to-let":
      return Account_Api_EntryPointEnum.EntryPointMortgageBuyToLet;
    case "certificate":
      return Account_Api_EntryPointEnum.EntryPointToolCertificateRepository;
    case "content-insurance":
      return Account_Api_EntryPointEnum.EntryPointTenantContentsInsurance;
    case "expense-tracker":
      return Account_Api_EntryPointEnum.EntryPointToolExpenseTracker;
    case "first-time-buyer":
      return Account_Api_EntryPointEnum.EntryPointMortgageFirstTimeBuy;
    case "gas-safety":
      return Account_Api_EntryPointEnum.EntryPointComplianceGasSafety;
    case "home-emergency":
      return Account_Api_EntryPointEnum.EntryPointInsuranceHei;
    case "inventory":
      return Account_Api_EntryPointEnum.EntryPointManagementInventory;
    case "maintenance":
      return Account_Api_EntryPointEnum.EntryPointManagementMaintenance;
    case "market-valuation":
      return Account_Api_EntryPointEnum.EntryPointToolMarketValuation;
    case "professional-photography":
      return Account_Api_EntryPointEnum.EntryPointManagementProfessionalPhotography;
    case "remortgage":
      return Account_Api_EntryPointEnum.EntryPointMortgageRemortgage;
    case "rent-guarantee-insurance":
      return Account_Api_EntryPointEnum.EntryPointInsuranceRgi;
    case "tenant-referencing":
      return Account_Api_EntryPointEnum.EntryPointManagementTenantReferencing;
    case "#webinars":
      return Account_Api_EntryPointEnum.EntryPointContentWebinar;
    default:
      return undefined;
  }
};
export const useEntryPointForRoute = () => ({ entryPointForRoute });

export const useSetEntryPoint = () => {
  const auth = useUserInfo();

  const setEntryPoint = useCallback(
    (src: string | undefined) => {
      try {
        const hasLS = isDOM && "localStorage" in window;
        if (hasLS) {
          const old = window.localStorage.getItem(ENTRYPOINT_STORAGE_KEY);
          if (old == null && auth?.id == null && typeof src !== "undefined") {
            window.localStorage.setItem(ENTRYPOINT_STORAGE_KEY, src);
          }
        }
      } catch {
        /* access to localstorage denied */
      }
    },
    [auth?.id]
  );
  return { setEntryPoint };
};

export const useSetEntryPointOnLoad = (src: string | undefined) => {
  const { setEntryPoint } = useSetEntryPoint();
  useEffect(() => {
    setEntryPoint(src);
  }, [setEntryPoint, src]);
};

const getEntryPoint = (): Account_Api_EntryPointEnum => {
  try {
    if (isDOM && "localStorage" in window) {
      return window.localStorage.getItem(
        ENTRYPOINT_STORAGE_KEY
      ) as Account_Api_EntryPointEnum;
    }
  } catch {
    /* access to localstorage is denied */
  }
  return Account_Api_EntryPointEnum.EntryPointJoinMashroom;
};
export const useGetEntryPoint = () => ({ getEntryPoint });

export const deleteEntryPoint = () => {
  try {
    if (isDOM && "localStorage" in window) {
      window.localStorage.removeItem(ENTRYPOINT_STORAGE_KEY);
    }
  } catch {
    /* access to localstorage is denied */
  }
};

export const useDeleteEntryPoint = () => ({ deleteEntryPoint });
