import React from "react";

const ArrowIcon = () => (
  <svg
    fill="none"
    height="10"
    viewBox="0 0 12 10"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 5.00066H10.4812M10.4812 5.00066L6.19343 0.712891M10.4812 5.00066L6.19343 9.28843"
      stroke="currentColor"
    />
  </svg>
);

export default ArrowIcon;
