import { Routes } from "@/constants";

const getRedirectToSearchParam = (redirectTo?: string) =>
  redirectTo ? `?redirectTo=${redirectTo}` : "";

class AuthUrlServiceBase {
  public getLoginUrl(redirectTo?: string) {
    return `${Routes.Login}${getRedirectToSearchParam(redirectTo)}`;
  }

  public getCreateAccountUrl(redirectTo?: string) {
    return `${Routes.CreateAccount}${getRedirectToSearchParam(redirectTo)}`;
  }
}

export const AuthUrlService = new AuthUrlServiceBase();
