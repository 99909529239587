import React from "react";

const TriangleRightIcon = () => (
  <svg
    fill="none"
    height="37"
    viewBox="0 0 31 37"
    width="31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 16.7679C31.3333 17.5378 31.3333 19.4622 30 20.232L3 35.8205C1.66666 36.5903 -1.71034e-06 35.6281 -1.64304e-06 34.0885L-2.80257e-07 2.91154C-2.12959e-07 1.37194 1.66667 0.40969 3 1.17949L30 16.7679Z"
      fill="currentColor"
    />
  </svg>
);

export default TriangleRightIcon;
