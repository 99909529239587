import React, { useMemo } from "react";

import TypeAnimation from "react-type-animation";

import { HomeTickerProps } from "./types";

import styles from "./styles.module.scss";

const HomeTicker = ({ content }: HomeTickerProps) => {
  const { options, main_text } = content;

  const sequence = useMemo(
    () => options.flatMap((o) => [o.option_text, 1800]),
    [options]
  );

  return (
    <h1 className={styles.root}>
      <span
        dangerouslySetInnerHTML={{
          __html: main_text,
        }}
      />
      <span className={styles.typeAnimation}>
        <TypeAnimation
          cursor
          repeat={Number.POSITIVE_INFINITY}
          sequence={sequence}
          wrapper="span"
        />
      </span>
    </h1>
  );
};

export default HomeTicker;
