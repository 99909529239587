import { isDOM } from "./isDom";

export type GAEvent =
  | {
      event: "proceed_to_signing";
      user_id: string;
      user_name: string;
      user_type: "landlord" | "tenant";
    }
  | {
      event: "messaging_function";
      user_id: string;
      user_name: string;
    }
  | {
      event: "iubenda_consent_given";
    }
  | {
      event: "VirtualPageview";
      virtualPageURL: string;
    }
  | { event: "new_account"; user_id: string }
  | { event: "sign_in"; user_id: string }
  | { event: "listing_complete"; propertyId: string }
  | { event: "preview_step"; propertyId: string }
  | { event: "find_tenants_form_submit" }
  | { event: "epc_purchased" }
  | { event: "photos_purchased" }
  | {
      event: "view_item_list" | "view_item" | "select_item";
      items: GAProduct[];
      item_list_id: "related_products";
      item_list_name: "Related products";
    }
  | {
      currency: "GBP";
      value: number;
      items: GAProduct[];
      event: "begin_checkout" | "add_payment_info" | "add_to_cart";
    }
  | {
      event: "purchase";
      currency: "GBP";
      transaction_id: string;
      value: number;
      items: GAProduct[];
    }
  | {
      event: `card_bought_${string}`;
      item_name: string | undefined;
      quantity: number;
      total_price: number;
      transaction_date: string | undefined;
      transaction_id: string | undefined;
      user_id: string | undefined;
    }
  | {
      event: "Order_flow";
      label: string;
      action: string;
    }
  | {
      event: "Stripe_filling";
      order_id: string;
    }
  | {
      event: "Stripe_success";
      order_id: string;
    }
  | {
      event: "Stripe_failure";
      order_id: string;
      error_code?: string;
    }
  | {
      event: "Stripe_show";
      code: string | string[];
      user_id: string;
      amount: number;
      order_id: string;
    };
export type GAProduct = {
  item_name: string | null | undefined;
  currency: "GBP";
  price: number | null | undefined;
};

export default function dataLayerPush(p: {
  args: GAEvent;
  nameInStorage?: string;
  onlyOnce?: boolean;
}) {
  if (!isDOM) {
    return;
  }

  if (p.onlyOnce) {
    if (
      p.nameInStorage == null ||
      window.localStorage.getItem(p.nameInStorage) != null
    ) {
      return;
    }

    window.localStorage.setItem(p.nameInStorage, JSON.stringify(p.args));
  }

  if ("dataLayer" in window) {
    window.dataLayer = window.dataLayer ?? [];
    try {
      if (
        "google_tag_manager" in window &&
        "GTM-M9BLWCJ" in window["google_tag_manager"]
      ) {
        window["google_tag_manager"]["GTM-M9BLWCJ"]?.dataLayer.reset();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("google_tag_manager dataLayer error", error);
    }
    window.dataLayer.push(p.args);
  }
}

export const dataLayerListingComplete = (propertyId: string) => {
  dataLayerPush({
    args: {
      propertyId,
      event: "listing_complete",
    },
    nameInStorage: `datalayer_listing_complete__${propertyId}`,
    onlyOnce: true,
  });
};

export const dataLayerPurchaseEvent = ({
  products,
  transaction_id,
}: {
  transaction_id: string;
  products: Array<{ title?: string | null; price?: number | null }>;
}) => {
  dataLayerPush({
    args: {
      currency: "GBP",
      transaction_id,
      value: totalProductsPrice(products),
      items: transformProductsToGA(products),
      event: "purchase",
    },
  });
};

export const dataLayerProductEvent = ({
  action,
  products,
}: {
  action: "begin_checkout" | "add_payment_info" | "add_to_cart";
  products: Array<{ title?: string | null; price?: number | null }>;
}) => {
  dataLayerPush({
    args: {
      currency: "GBP",
      value: totalProductsPrice(products),
      items: transformProductsToGA(products),
      event: action,
    },
  });
};

export type GAViewAndSelectProductAction =
  | "view_item_list"
  | "view_item"
  | "select_item";
export const dataLayerViewAndSelectItemEvent = ({
  action,
  products,
}: {
  action: GAViewAndSelectProductAction;
  products: Array<{ title?: string | null; price?: number | null }>;
}) => {
  dataLayerPush({
    args: {
      item_list_id: "related_products",
      item_list_name: "Related products",
      items: transformProductsToGA(products),
      event: action,
    },
  });
};

function transformProductsToGA(
  products: Array<{ title?: string | null; price?: number | null }>
): GAProduct[] {
  return products.map((product) => ({
    item_name: product.title,
    currency: "GBP",
    price: product.price ?? undefined,
  }));
}
const totalProductsPrice = (products: Array<{ price?: number | null }>) =>
  products.reduce(
    (sum, product) =>
      sum + (typeof product.price === "number" ? product.price : 0),
    0
  );

// proceed_to_signing event
export const dataLayerProceedToSigning = ({
  user_id,
  user_name,
  user_type,
}: {
  user_name: string;
  user_id: string;
  user_type: "landlord" | "tenant";
}) => {
  dataLayerPush({
    args: {
      user_id,
      user_name,
      user_type,
      event: "proceed_to_signing",
    },
  });
};

// user click message button
export const dataLayerUserClickMessageButton = ({
  user_id,
  user_name,
}: {
  user_id: string;
  user_name;
}) => {
  dataLayerPush({
    args: {
      event: "messaging_function",
      user_id,
      user_name,
    },
  });
};
